<script setup lang="ts">
const route = useRoute();
const appConfig = useAppConfig();
const { isHelpSlideoverOpen } = useDashboard();

const links = [
  {
    id: "home",
    label: "Home",
    icon: "i-heroicons-home",
    to: "/",
    tooltip: {
      text: "Home",
      shortcuts: ["G", "H"],
    },
  },
  {
    id: "gallery",
    label: "Dressroom",
    icon: "i-heroicons-camera",
    to: "/gallery",
    tooltip: {
      text: "Dressroom",
      shortcuts: ["G", "H"],
    },
  },
  {
    id: "garnments",
    label: "Garnments",
    icon: "i-heroicons-cube-transparent",
    to: "/garnments",
    tooltip: {
      text: "Garnments",
      shortcuts: ["G", "H"],
    },
  },
  {
    id: "models",
    label: "Models",
    icon: "i-heroicons-user",
    to: "/models",
    tooltip: {
      text: "Models",
      shortcuts: ["G", "H"],
    },
  },
  /*{
    id: "inbox",
    label: "Inbox",
    icon: "i-heroicons-inbox",
    to: "/inbox",
    badge: "4",
    tooltip: {
      text: "Inbox",
      shortcuts: ["G", "I"],
    },
  },*/
  {
    id: "users",
    label: "Users",
    icon: "i-heroicons-user-group",
    to: "/users",
    tooltip: {
      text: "Users",
      shortcuts: ["G", "U"],
    },
  },
  {
    id: "settings",
    label: "Settings",
    to: "/settings",
    icon: "i-heroicons-cog-8-tooth",
    children: [
      {
        label: "General",
        to: "/settings",
        exact: true,
      },
      {
        label: "Members",
        to: "/settings/members",
      },
      {
        label: "Notifications",
        to: "/settings/notifications",
      },
    ],
    tooltip: {
      text: "Settings",
      shortcuts: ["G", "S"],
    },
  },
];

const footerLinks = [
  {
    label: "Invite people",
    icon: "i-heroicons-plus",
    to: "/settings/members",
  },
  {
    label: "Help & Support",
    icon: "i-heroicons-question-mark-circle",
    click: () => (isHelpSlideoverOpen.value = true),
  },
];

const groups = [
  {
    key: "links",
    label: "Go to",
    commands: links.map((link) => ({
      ...link,
      shortcuts: link.tooltip?.shortcuts,
    })),
  },
  {
    key: "code",
    label: "Code",
    commands: [
      {
        id: "source",
        label: "View page source",
        icon: "i-simple-icons-github",
        click: () => {
          window.open(
            `https://github.com/nuxt-ui-pro/dashboard/blob/main/pages${
              route.path === "/" ? "/index" : route.path
            }.vue`,
            "_blank"
          );
        },
      },
    ],
  },
];

//const stripeClient = await useClientStripe();

const defaultColors = ref(
  ["green", "teal", "cyan", "sky", "blue", "indigo", "violet"].map((color) => ({
    label: color,
    chip: color,
    click: () => (appConfig.ui.primary = color),
  }))
);
const colors = computed(() =>
  defaultColors.value.map((color) => ({
    ...color,
    active: appConfig.ui.primary === color.label,
  }))
);
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
        <template #left>
          <TeamsDropdown />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UDashboardSearchButton />
        </template>

        <UDashboardSidebarLinks :links="links" />

        <UDivider />

        <UDashboardSidebarLinks
          :links="[{ label: 'Colors', draggable: true, children: colors }]"
          @update:links="(colors) => (defaultColors = colors)"
        />

        <div class="flex-1" />

        <UpgradeModal />

        <UDashboardSidebarLinks :links="footerLinks" />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <!-- ~/components/UserDropdown.vue -->
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <!-- ~/components/HelpSlideover.vue -->
    <HelpSlideover />
    <!-- ~/components/NotificationsSlideover.vue -->
    <NotificationsSlideover />

    <!-- ~/components/NotificationsSlideover.vue -->
    <GarnmentSlideover />

    <ClientOnly>
      <LazyUDashboardSearch :groups="groups" />
    </ClientOnly>
  </UDashboardLayout>
</template>

<style>
.gradient-border {
  background-image: linear-gradient(#191a1d, #191a1d),
    linear-gradient(279.06deg, #ff9a9a, #c296ff 52.08%, #aaedfd);
  border: double 1px transparent;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 10px;
}

.gradient-wrapper {
  background: linear-gradient(279.06deg, #ff9a9a, #c296ff 52.08%, #aaedfd);
  color: #000;
  padding: 10px 20px;
}
</style>
