<script setup>
const isOpen = ref(false);
const isPay = ref(false);
const isYearly = ref(true);
const selectedPlan = ref(0);
const { data: page } = await useAsyncData("pricing", () =>
  queryContent("/pricing").findOne()
);

async function pay(planValue) {
  console.log("subscribe");
  isPay.value = true;
  selectedPlan.value = planValue;
  // price: price_1OMfJBAe11ci6r9VvEmiDpiz
}
</script>
<template>
  <UButton
    block
    padded
    color="gray"
    variant="full"
    label="Upgrade"
    @click="isOpen = true"
  />
  <UModal
    v-model="isOpen"
    class="flex items-center justify-center"
    :ui="{
      width: 'w-full sm:max-w-7xl',
      overlay: {
        background: 'bg-black/75 dark:bg-black/75 backdrop-blur',
      },
    }"
  >
    <UModal
      v-model="isPay"
      class="flex items-center justify-center"
      :ui="{
        width: 'w-full sm:max-w-4xl',
        overlay: {
          background: 'bg-black/75 dark:bg-black/75 backdrop-blur',
        },
      }"
    >
      <PaymentModal
        v-model="isPay"
        :plan="page.plans[selectedPlan]"
        :isYearly="isYearly"
      />
    </UModal>

    <div
      class="rounded-xl relative flex h-full w-full items-stretch overflow-hidden shadow-[0px_14px_34px_#000000]"
    >
      <div class="w-[30%]">
        <img class="w-full h-full object-cover" src="/glow555.png" />
      </div>
      <div
        class="flex flex-1 flex-col items-center bg-[#121316] shadow-[0_14px_34px_rgba(0,0,0,1)] p-10"
      >
        <UPricingToggle v-model="isYearly" class="mx-auto max-w-xs mb-10">
          <template #right>
            Yearly

            <div
              class="flex-none rounded uppercase leading-none tracking-widest bg-[#161616] bg-opacity-80 px-1 text-xs ml-3 py-1 text-8 backdrop-blur-sm bg-[#AAEDFD]/[.2]"
            >
              Save 15%
            </div>
          </template>
        </UPricingToggle>

        <UPricingGrid :compact="false" class="w-full">
          <UPricingCard2
            v-for="(plan, index) in page.plans"
            :key="index"
            v-bind="plan"
            :price="isYearly ? plan.price.year : plan.price.month"
            discount=""
            :cycle="isYearly ? '/month' : '/month'"
            :price-desc="isYearly ? 'Billed yearly' : 'Billed monthly'"
            :button="{
              label: 'Upgrade Plan',
              variant: 'main',
              click: () => pay(index),
            }"
            orientation="vertical"
            align="bottom"
          />
        </UPricingGrid>
      </div>
    </div>
    <UButton
      icon="i-heroicons-x-mark"
      class="absolute right-4 top-4"
      @click="isOpen = false"
    />
  </UModal>
</template>
